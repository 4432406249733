/* Common Imports */
import React from "react";
import { withRouter } from "next/router";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import { Button, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  link: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "&:hover": {
      textDecoration: "none",
    },
  },
  navButtonActive: {
    borderBottomStyle: "solid",
    borderWidth: "0px 0px 2px 0px",
    borderImage: "-webkit-linear-gradient(0deg, #2CB646, #2CB646) 1",
    padding: "1.2rem 0.5rem 1rem 0rem",
    borderRadius: 0,
    fontWeight: "500",
    fontSize: "1rem",
    lineHeight: "1rem",
    letterSpacing: "0.047rem",
    textTransform: "capitalize",
    letterSpacing: "0.047rem",
  },
  navButton: {
    color: "#666666",
    borderBottom: "solid 3px #FFFFFF",
    // padding: "1.2rem 1rem 1.3rem 1rem",
    padding: "1.2rem 0.5rem 1.3rem 0.2rem",
    borderRadius: 0,
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "1rem",
    letterSpacing: "0.047rem",
    textTransform: "capitalize",
    letterSpacing: "0.047rem",
  },
  hideThis: {
    display: "none",
  },
  marginLeft: {
    marginLeft: "0.5rem",
  },
  marginLeftActive: {
    color: "#2CB646",
    // background: "-webkit-linear-gradient(0deg, #F74134, #EB1C61)",
    // "-webkit-background-clip": "text",
    // "-webkit-text-fill-color": "transparent",
    marginLeft: "0.5rem",
  },
}));

const NavButton = (props) => {
  const classes = useStyles();
  // const navIconClass =
  //   props.router.pathname == props.href ? "" : classes.hideThis;
  return (
    <React.Fragment>
      <Link
        href={props.href}
        rel="noopener"
        aria-label={props.text}
        title={props.text}
        className={classes.link}
      >
        <Button
          aria-controls={props.ariaControls}
          aria-haspopup={props.ariaHaspopup}
          onClick={props.onClick}
          className={
            props.router.pathname == props.href
              ? classes.navButtonActive
              : classes.navButton
          }
        >
          <span
            className={
              props.router.pathname == props.href
                ? classes.marginLeftActive
                : classes.marginLeft
            }
          >
            {props.text}
          </span>
        </Button>
      </Link>
    </React.Fragment>
  );
};

export default withRouter(NavButton);
