/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

/* Component Imports */

import {
  AppBar,
  Toolbar,
  Link,
  useScrollTrigger,
  Button,
  IconButton,
} from "@material-ui/core";
import NavButton from "./navbar-components/NavButton";
import SideNav from "./navbar-components/SideNav";

/* Data Imports */

import AppConfig from "../../AppConfig";

const useStyles = makeStyles((theme) => ({
  /* Navbar New */
  appBar: {
    backgroundColor: "#FFFFFF",
    height: "3.72rem",
    padding: "0rem 1rem 0rem 1rem",
  },
  toolBar: {
    width: "100%",
    height: "4rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // [theme.breakpoints.down("xs")]: {
    //   justifyContent: "center",
    //   padding: 0,
    // },
    margin: "0rem 0rem 0rem 0rem",
  },
  logoLink: {
    width: 80,
    height: 22,
  },
  menuButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#808080",
    padding: "0rem 0rem 0rem 0rem",
    width: "1.5rem",
    height: "1.5rem",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mobileLogoContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginLeft: "-4rem",
    },
  },
  logoImg: {
    height: "1.5rem",
    width: "1.5rem",
  },
  display: {
    background: "transparent",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "&:hover": {
      background: "transparent",
    },
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const Navbar = (props) => {
  const classes = useStyles();
  const [drawerSwitch, setDrawerSwitch] = React.useState(false);
  return (
    <React.Fragment>
      {/* SideNav Phone */}
      <SideNav
        open={drawerSwitch}
        onClose={() => {
          setDrawerSwitch(false);
        }}
      />

      {/* Navbar Desktop */}
      <ElevationScroll {...props}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Button
              color="inherit"
              aria-label="Open Drawer"
              edge="start"
              style={{
                display: props.hideNavLinks ? "none" : undefined,
              }}
              onClick={() => {
                setDrawerSwitch(!drawerSwitch);
              }}
              className={classes.menuButton}
            >
              <img
                decode="async"
                alt={AppConfig.imageAltText}
                longdesc={props.href ? props.href : "#"}
                loading="lazy"
                referrerPolicy="no-referrer"
                height={24}
                width={24}
                src="/images/icons/misc/menu.svg"
                className={classes.logoImg}
              />
            </Button>

            {/* Logo */}
            <div className={classes.mobileLogoContainer}>
              <Link
                href="/"
                rel="noopener"
                aria-label="PSO"
                title="Home"
                className={classes.logoLink}
              >
                <img
                  loading="lazy"
                  decode="async"
                  alt={AppConfig.imageAltText}
                  longdesc="https://psolifestyle.com"
                  referrerPolicy="no-referrer"
                  width={100}
                  height={28}
                  style={{ marginRight: "0.25rem" }}
                  src="/images/logo/logo.svg"
                />
              </Link>
            </div>
            {/* Page Links */}
            <div>
              {props.hideNavLinks ? undefined : (
                <div>
                  <NavButton href="/" text="Home" />
                  <NavButton href="#about" text="About" />
                  <NavButton href="/menu" text="Menu" />
                  <NavButton href="#contact" text="Contact" />
                  <Link
                    href="https://www.swiggy.com/restaurants/paradigm-shift-organica-hsr-hsr-layout-bangalore-360483"
                    rel="noopener"
                    aria-label="PSO"
                    title="Swiggy"
                    target="_blank"
                  >
                    <IconButton className={classes.display}>
                      <img
                        decode="async"
                        alt={AppConfig.imageAltText}
                        longdesc="https://psolifestyle.com"
                        loading="lazy"
                        referrerPolicy="no-referrer"
                        height={30}
                        width={30}
                        // style={{ marginRight: "0.5rem" }}
                        src="/images/others/swiggy_logo.webp"
                      />
                    </IconButton>
                  </Link>
                  <Link
                    href="https://www.zomato.com/bangalore/paradigm-shift-organica-hsr-bangalore"
                    rel="noopener"
                    aria-label="PSO"
                    title="Zomato"
                    target="_blank"
                  >
                    <IconButton className={classes.display}>
                      <img
                        decode="async"
                        alt={AppConfig.imageAltText}
                        longdesc="https://psolifestyle.com"
                        loading="lazy"
                        referrerPolicy="no-referrer"
                        height={30}
                        width={30}
                        // style={{ marginRight: "0.5rem" }}
                        src="/images/others/zomato_logo.webp"
                      />
                    </IconButton>
                  </Link>
                  {/* <Button
                    className={classes.callBtn}
                    href="tel:+91886102999"
                    title="Call Us"
                  >
                    Call Us
                  </Button> */}
                </div>
              )}
            </div>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar className={classes.toolBar} />
    </React.Fragment>
  );
};

export default Navbar;
