/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Link } from "@material-ui/core";

/* Data Imports */

import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  socialLinkIcon: {
    margin: "0rem 0.5rem 0rem 0.5rem",
  },
  socialIcon: {
    // [theme.breakpoints.down("sm")]: {
    //   textAlign: "center",
    // },
    margin: "1rem 0rem 0rem 0rem",
    textAlign: "center",
  },
}));

const FooterSocialIcons = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.socialIcon}>
        <Link
          href="//instagram.com/psolifestyle"
          rel="noopener"
          title="Follow PSO Lifestyle on Instagram"
          target="_blank"
        >
          <img
            src="/images/icons/social/instagram.svg"
            alt={AppConfig.imageAltText}
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
            longdesc="https://psolifestyle.com"
            className={classes.socialLinkIcon}
          />
        </Link>
        <Link
          href="//facebook.com/psolifestyle"
          rel="noopener"
          title="Follow PSO on Facebook"
          target="_blank"
        >
          <img
            src="/images/icons/social/facebook.svg"
            alt={AppConfig.imageAltText}
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
            longdesc="https://psolifestyle.com"
            className={classes.socialLinkIcon}
          />
        </Link>
        <Link
          href="//twitter.com/psolifestyle"
          rel="noopener"
          title="Follow PSO Lifestyle on Twitter"
          target="_blank"
        >
          <img
            src="/images/icons/social/twitter.svg"
            alt={AppConfig.imageAltText}
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
            longdesc="https://psolifestyle.com"
            className={classes.socialLinkIcon}
          />
        </Link>
        {/* <Link
          href="//in.pinterest.com/psolifestyle"
          rel="noopener"
          title="Follow PSO Lifestyle on Pinterest"
          target="_blank"
        >
          <img
            src="/images/icons/social/pinterest.svg"
            alt={AppConfig.imageAltText}
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
            longdesc="https://psolifestyle.com"
            className={classes.socialLinkIcon}
          />
        </Link>
        <Link
          href="//in.linkedin.com/company/psolifestyle"
          rel="noopener"
          title="Follow PSO Lifestyle on LinkedIn"
          target="_blank"
        >
          <img
            src="/images/icons/social/linkedin.svg"
            alt={AppConfig.imageAltText}
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
            longdesc="https://psolifestyle.com"
            className={classes.socialLinkIcon}
          />
        </Link> */}
        <Link
          href="//wa.me/+919632903999"
          rel="noopener"
          title="Follow PSO Lifestyle on LinkedIn"
          target="_blank"
        >
          <img
            src="/images/icons/social/whatsapp.svg"
            alt={AppConfig.imageAltText}
            width={32}
            height={32}
            loading="lazy"
            referrerPolicy="no-referrer"
            longdesc="https://psolifestyle.com"
            className={classes.socialLinkIcon}
          />
        </Link>
      </div>
    </React.Fragment>
  );
};

export default FooterSocialIcons;
