/* Common Imports */

import React from "react";

/* Component Imports */

import PSOContainer from "./PSOContainer";
import FooterCopyrightInfo from "./footer-components/FooterCopyrightInfo";
import FooterSocialIcons from "./footer-components/FooterSocialIcons";

const Footer = () => {
  return (
    <React.Fragment>
      <div
        style={{
          padding: "1rem 0rem 0rem 0rem",
          backgroundColor: "#FCFCFC",
          boxShadow:
            "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
        }}
      >
        <PSOContainer>
          <FooterCopyrightInfo />
          <FooterSocialIcons />
        </PSOContainer>
      </div>
    </React.Fragment>
  );
};

export default Footer;
