/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Data Imports */

import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  sketch: {
    position: "absolute",
    bottom: 0,
    left: 0,
    zIndex: -1,
    height: "17.5rem",
    width: "17.5rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      height: "48rem",
      width: "96rem",
    },
    [theme.breakpoints.only("xl")]: {
      height: "68rem",
      width: "120rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      height: "90rem",
      width: "160rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
      height: "140rem",
      width: "240rem",
    },
  },
  sketchipad: {
    position: "absolute",
    zIndex: -1,
    height: "17.5rem",
    width: "17.5rem",
    [theme.breakpoints.only("md")]: {
      height: "auto",
      width: "64rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  sketchmobile: {
    position: "absolute",
    zIndex: -1,
    height: "17.5rem",
    width: "17.5rem",
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      height: "43rem",
      width: "17.5rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 35)]: {
      height: "45rem",
      width: "20rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 40)]: {
      height: "45rem",
      width: "22.5rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 80)]: {
      height: "55rem",
      width: "23.45rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
      height: "55rem",
      width: "25.7rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 250)]: {
      height: "70rem",
      width: "48rem",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const BackgroundPlaceholder = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <img
        className={classes.sketch}
        decode="async"
        alt={AppConfig.imageAltText}
        longdesc="https://heavie.co"
        loading="lazy"
        referrerPolicy="no-referrer"
        height={681}
        width={1244}
        src="/images/illustrations/background.png"
      />
      <img
        className={classes.sketchmobile}
        decode="async"
        alt={AppConfig.imageAltText}
        longdesc="https://heavie.co"
        loading="lazy"
        referrerPolicy="no-referrer"
        height={389}
        width={789}
        src="/images/illustrations/backgroundmobile.png"
      />
      <img
        className={classes.sketchipad}
        decode="async"
        alt={AppConfig.imageAltText}
        longdesc="https://heavie.co"
        loading="lazy"
        referrerPolicy="no-referrer"
        height={389}
        width={789}
        src="/images/illustrations/backgroundipad.png"
      />
    </React.Fragment>
  );
};

export default BackgroundPlaceholder;
