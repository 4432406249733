/* React Imports */

import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "next/router";
// import Script from "next/script";

/* Component Imports */

import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Fabs from "./Fabs";
import PSOContainer from "./PSOContainer";
import BackgroundPlaceholder from "./subcomponents/BackgroundPlaceholder";

/* Data Imports */

import AppConfig from "../../AppConfig";

const Layout = (props) => {
  return (
    <React.Fragment>
      <div id="rootDiv">
        <Header
          keywords={
            props.keywords
              ? props.keywords
              : "pso,paradigmshiftorganica,organic,plant,lifestyle,environment,plantbased,bread,cookies,icecream,restaurant,food,eat,pizza,cake,juice,smoothie,milk,butter,pwa,next,bangalore,bengaluru,buy,agriculture,farm,design,material,ui,react"
          }
          description={
            props.description
              ? props.description
              : "PSO is a brand committed to plant based organic food and sustainable products and is based in Bengaluru, Karnataka"
          }
          title={props.title ? props.title : "PSO"}
          canonicalUrl={
            props.canonicalUrl
              ? props.canonicalUrl
              : AppConfig.urlPrefix + props.router.asPath.substring(1)
          }
        />
        {props.hideNavbar ? undefined : (
          <Navbar hideNavLinks={props.hideNavLinks} />
        )}
        <Helmet>
          <html lang="en-GB" />
        </Helmet>
        {props.backgroundPlaceholder ? <BackgroundPlaceholder /> : undefined}
        <PSOContainer>{props.children}</PSOContainer>
        {props.hideFabs ? undefined : <Fabs />}
        {props.hideFooter ? undefined : <Footer id="footer" />}
        {/* <Script
          type="application/ld+json"
          dangerouslySetInnerHTML={
            props.jsonLDSchema
              ? props.jsonLDSchema
              : {
                  __html: JSON.stringify({
                    "@context": "https://schema.org/",
                    "@type": "RealEstateListing",
                    name: "Party Coffee Cake",
                    author: {
                      "@type": "Person",
                      name: "Mary Stone",
                    },
                    datePublished: "2018-03-10",
                    description:
                      "This coffee cake is awesome and perfect for parties.",
                    prepTime: "PT20M",
                  }),
                }
          }
        /> */}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Layout);
